import React from 'react'

const Contact = () => (
  <section
    className="container content-section text-center"
    data-cy="contact-component"
    id="contact"
  >
    <div className="row">
      <h2 className="home-titles"> Contact Me</h2>
      <p className="about-p">
        The best way to reach me is via email:{' '}
        <a title="email link" href="mailto:nickjtorres5@gmail.com">nickjtorres5@gmail.com</a>
      </p>
      <p className="about-p">
        You can also view my{' '}
        <a title="linkedin link" href="https://www.linkedin.com/in/nicholas-torres-07b28b12a">linkedin</a>
      </p>
      <p className="about-p">
        And my  <a title="github link" href="https://github.com/yourkingnico">github</a>
      </p>
    </div>
  </section>
)
export default Contact
