import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Link } from 'gatsby'
import Travel from './travel'

const AboutMe = () => (
  <section
    className="container content-section text-center"
    id="about"
    data-cy="about-component"
  >
    <div className="row">
      
      <h1 className="home-titles" style={{paddingTop: '15%'}}> About Me</h1>
      <p className="about-p">
        Welcome to my site! My name is Nick, and I'm a web developer and quality
        assurance engineer originally from Illinois. I have a degree in Computer
        Science from Ball State University, and have worked in both software
        development and quality assurance roles for the past five years. As
        someone who understands development as well as quality assurance, I take
        test coverage seriously. Whether it be writing unit tests for an
        individual function, or developing end to end regression suites, I
        understand the differences and importance of having adequate test
        coverage throughout an application. When testing I consider myself a
        user advocate, and try to holistically test features whenever possible.
        Some people may be satisfied with the minimal required scenarios tested,
        but I always consider the different browsers, devices, and challenges
        users may experience. Although I have a great eye for manual testing, I
        prefer to automate test cases whenever possible. In the past I've worked with 
        a variety of automation testing solutions like Selenium, Cypress, Katalon Studio,
        and Webdriver.io. In my free time I enjoy using react and react native for personal
        web and android projects, and have taken an interest in learning more about web 
        accessibility. Some of my other hobbies include fishing, <Link to="galleryDirectory"> 
        Hiking</Link>, and
        <Travel />
      </p>
      <Container>
        <Row>
          <h2 className="language-title" style={{paddingBottom:'5%'}}>What I'm working with</h2>
        </Row>
        <Row>
          <ul style={{float: 'left',  listStylePosition:'outside', fontSize: '20px', textAlign: 'left'}}>
            
            <li>Javascript</li>
            <li>Git</li>
            <li>CSS</li>
            <li>HTML</li>
            <li>Java</li>
            <li>React</li>        
            <li>Selenium, Katalon Studio</li>
            <li>SauceLabs, Browserstack</li>

          </ul>
        </Row>
      </Container>
    </div>
  </section>
)

export default AboutMe
