import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const Experience = ({
  description,
  date,
  image,
  isInverted,
  link,
}) => (
  <li className={isInverted ? 'timeline-inverted' : ''}>
    <div className="timeline-image">
      <div className="img-circle img-responsive">
      <GatsbyImage image={image} alt="" style={{ borderRadius: '50%' }} />
      </div>
    </div>
    <div className="timeline-panel">
      <div className="timeline-heading">
        {date? ( <h4> {date} </h4>) : (<></>)}
      </div>
      {link ? (
        <a target="_blank" href={link}>
          <div className="timeline-body">
            <p> {description} </p>
          </div>
        </a>
      ) : (
        <div className="timeline-body">
          <p> {description} </p>
        </div>
      )}
    </div>
  </li>
)

export default Experience
