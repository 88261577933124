import { getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import React from 'react'
import Layout from 'components/layout'
import AboutMe from 'components/aboutMe'
import TimeLine from 'components/timeline'
import Contact from 'components/contact'

const Home = ({ data, location }) => {

  return (
    <Layout location={location} isHome={true} title="nicks site">
      <AboutMe />
      <TimeLine />
      <Contact />
    </Layout>
  )
}

export default Home
