import React from 'react'
import useWindowDimensions from './useWindowDimensions'

const Travel = () => {
  const { width } = useWindowDimensions()

  return width > 1100 ? (
    <a
      href="https://yourkingnico.github.io/portfolio/indexG.html"
      target="__blank"
    >
      {' '}
      travel.
    </a>
  ) : (
    <> travel. </>
  )
}

export default Travel
