import React from 'react'
import Experience from './experience'
import { StaticQuery, graphql } from 'gatsby'

export default function Timeline () {
  return (<StaticQuery
    query={graphql`
      query TimelineQuery {
        default: file(name: { eq: "default" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        baracktocat: file(name: { eq: "baracktocat" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        gradhs: file(name: { eq: "gradhs" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        charlie: file(name: { eq: "charlie" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        chem: file(name: { eq: "chem" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        corps: file(name: { eq: "corps" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        bp: file(name: { eq: "bp" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        mwr: file(name: { eq: "mwr" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        info: file(name: { eq: "info" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
            )
          }
        }
        mere: file(name: { eq: "mere" }) {
          childImageSharp {
            gatsbyImageData(
              width: 400
              quality:100
            )
          }
        }
      }
    `}
    render={data =>  (
   
      <section className="container content-section text-center">
        <div className="row">
          <div className="col-md-10 col-md-offset-1">
            <h3 id="timeline" className="home-titles">
              Timeline
            </h3>
            <ul className="timeline">
              <Experience
                date="October 2019 - Present"
                description="Senior QA Engineer, Dotdash Meredith"
                image={data.mere.childImageSharp.gatsbyImageData}
                link={'https://meredith.com'}
                isInverted={true}
              />
              <Experience
                date="June 2018 - May 2019"
                description="Associate Software Developer, Infosys Indianapolis"
                image={data.info.childImageSharp.gatsbyImageData}
              />
              <Experience
                date="August 2017 - April 2018"
                description="Completed Midwest Radio Android Application"
                image={data.mwr.childImageSharp.gatsbyImageData}
                link={
                  'https://github.com/middletownradio/android/blob/master/midwest_radio_android/README.md'
                }
                isInverted={true}
              />
              <Experience
                date="August 2017 - January 2018"
                description="Worked with BidPal, Quality Assurance Associate"
                image={data.bp.childImageSharp.gatsbyImageData}
              />
              <Experience
                date="September 2016 - August 2017"
                description="Employed with the Ball State Digital Corps, Development"
                image={data.corps.childImageSharp.gatsbyImageData}
                isInverted={true}
                link="https://digitalcorps.bsu.edu/"
              />
              <Experience
                date="February 2016 - May 2016"
                description="Employed with Ball State Department of Chemistry"
                image={data.chem.childImageSharp.gatsbyImageData}
              />
              <Experience
                date="August 2014"
                description="Started at Ball State University"
                image={data.charlie.childImageSharp.gatsbyImageData}
                isInverted={true}
              />
              <Experience
                date="June 2014"
                description="Graduated from Willowbrook High School"
                image={data.gradhs.childImageSharp.gatsbyImageData}
              />
              <Experience
                date="August 2013"
                description="Started programming in Java"
                image={data.baracktocat.childImageSharp.gatsbyImageData}
                isInverted={true}
              />
              <Experience
                image={data.default.childImageSharp.gatsbyImageData}
              />
            </ul>
          </div>
        </div>
      </section>
    )}
  />)
}
